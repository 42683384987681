.user-menu-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px;
}

.user-menu-menu-item:hover {
  cursor: pointer;
  color: dodgerblue;
}

.user-menu-display-name {
  color: lightgray;
  font-size: 12px;
}