/*.bar {
  fill: dodgerblue;
}*/

.axis {
  font: 10px sans-serif;
}

.axis path,
.axis line {
  fill: none;
  stroke: rgba(0,0,0,0.54);
  shape-rendering: crispEdges;
}

.x.axis path {
  display: none;
}

.y.axis path {
  display: none;
}

.zoom {
  cursor: move;
  fill: none;
  pointer-events: all;
}

.speedLine, .fuelLineBefore, .fuelLineAfter {
  clip-path: url(#clip);
}

.actionFocus, .recommendationsFocus, .planningLine {
  clip-path: url(#clip);
}

.prices {
  clip-path: url(#clip);
}