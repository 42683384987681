html body {
  font-family: roboto;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

tr.trailerRowSelected {
  background: #f0f0f0;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.reflex-container.vertical > .reflex-splitter:hover, .reflex-container.vertical > .reflex-splitter.active {
  border-right: 2px solid dodgerblue !important;
  border-left: 2px solid dodgerblue !important;
}

.reflex-container.vertical > .reflex-splitter {
  border-right: 2px solid #c6c6c6 !important;
  border-left: 2px solid #c6c6c6 !important;
}

.mapboxgl-popup {
  z-index: 100;
  -webkit-user-select: text;
          user-select: text;
  cursor: text;
}

.selectable-table-row {
  cursor: pointer;
}
.selectable-table-row.highlighted {
  background-color: #e6f7ff;
}
.refuel-table-csv-button {
  position: relative;
  margin-left: 16px;
}
.refuel-table-csv-button-element {
  position: absolute;
  top: -50px;
}
.react-clock {
  display: block;
  position: relative;
}
.react-clock,
.react-clock *,
.react-clock *:before,
.react-clock *:after {
  box-sizing: border-box;
}
.react-clock__face {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid black;
  border-radius: 50%;
}
.react-clock__hand {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__hand__body {
  position: absolute;
  background-color: black;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.react-clock__mark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__mark__body {
  position: absolute;
  background-color: black;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.react-clock__second-hand__body {
  background-color: red;
}

/*.bar {
  fill: dodgerblue;
}*/

.axis {
  font: 10px sans-serif;
}

.axis path,
.axis line {
  fill: none;
  stroke: rgba(0,0,0,0.54);
  shape-rendering: crispEdges;
}

.x.axis path {
  display: none;
}

.y.axis path {
  display: none;
}

.zoom {
  cursor: move;
  fill: none;
  pointer-events: all;
}

.speedLine, .fuelLineBefore, .fuelLineAfter {
  -webkit-clip-path: url(#clip);
          clip-path: url(#clip);
}

.actionFocus, .recommendationsFocus, .planningLine {
  -webkit-clip-path: url(#clip);
          clip-path: url(#clip);
}

.prices {
  -webkit-clip-path: url(#clip);
          clip-path: url(#clip);
}
.settings-form-title {
  margin: 16px 0;
}

.settings-form-element {
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.settings-form-element-input {
  max-width: 200px !important;
}

.settings-form-element-title {
  margin-right: 16px;
  vertical-align: middle;
  width: 80%;
}

.ant-form-item {
  margin-bottom: 8px !important;
}

.settings-form-save-button {
  margin: 8px !important;
}

.settings-dropdown-menu {
  max-width: 200px !important;
}

.delete-refund-button {
  margin-left: 10px;
  cursor: pointer;
}

.settings-close-button-container {
  text-align: end !important;
  height: 56px;
  line-height: 56px;
  padding: 0;
}

.settings-close-button {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  padding: 16px;
}

.settings-container {
  height: 100%;
  background-color: white;
  padding: 16px 50px 50px;
  overflow: auto;
}

.uploadButton{
  text-align: center;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;;
	height: 50px;
  padding: 5px 15px;
}

.disableUpload{
  text-align: center;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  cursor: not-allowed !important;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;;
	height: 50px;
  padding: 5px 15px;
  color: darkgrey;
}

.disableUpload:hover{
  text-align: center;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  cursor: not-allowed !important;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;;
	height: 50px;
  padding: 5px 15px;
  color: darkgrey;
}

.platts-table .ant-table-thead > tr > th, .platts-table .ant-table-tbody > tr > td {
  padding: 10px 10px !important;
  white-space: nowrap !important;
}

.platts-table .ant-table-body{
  overflow: scroll;
}

.fleet-cards-table .ant-table-body{
  overflow-x: auto !important
}

.fleet-cards-table .ant-table-tbody > tr > td, .ant-table-thead > tr > th{
  border-bottom: 1px solid #bbbbbb
}

.dataColumns .ant-input{
  min-width: 200px !important;
}

.filterapplied{
  color: #1890ff;
}

.text-red{
  color: red;
}

.text-green{
  color: green;
}

.intend-text-60{
  text-indent: 60px;
}

.tabIcons{
  font-size: 14pt;
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.25em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  opacity: 0.7;
}

.checkCss{
  background-color: grey;
}

.editPopOver .ant-popover-arrow, .editPopOver .ant-popover-inner, .editPopOver .ant-popover-inner-content {
  background-color: #EEF1F4 !important;
}

.editPopOver .ant-popover-arrow{
  border-color: #EEF1F4 !important;
}

.editPopOver .ant-popover-inner-content{
  box-shadow: 3px 3px 15px 5px grey;
  border-radius: 7px;
}

.filterapplied{
  color: #1890ff;
}

.settingsButton{
  text-align: center;
  color: grey;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  height: 50px;
  font-size: 15pt;
  padding: 15px 5px 15px 0px;
}

.settingsButtonActive {
  text-align: center;
  color:  #1890ff;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  height: 50px;
  font-size: 15pt;
  padding: 15px 5px 15px 0px;
}

.disableIcon{
  cursor: not-allowed !important;
  text-align: center;
  color: grey;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  height: 50px;
  font-size: 15pt;
  padding: 15px 5px 15px 0px;
}

.fuelcardControl{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  height: 50px;
}

.templateControl{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  height: 50px;
  padding-right: 10px;
}

.errorLabel{
  color: red;
  padding-bottom: 15px;
  padding-left: 10px;
}

.infoIcon{
  font-size: 18pt;
  color: #1890ff;
}

.fontBig{
  font-size: 20pt;
  color: #1890ff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.emailWithCopy{
  cursor: 'text';
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ant-select-selection__rendered > ul{
  padding-right: 20px;
}

.btnSaveAndProcess{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 50px;
}

.lastuploadedfileinfo{
  color: green;
}

.platts-table .ant-table-footer{
  padding: 8px 12px !important;
  font-weight: bold;
  font-style: italic;
}

.platts-table .ant-table-pagination{
  margin: 0px !important;
}

.trucks-table .ant-table-body{
  min-height: 270px;
}

.trucks-table .ant-pagination-total-text{
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
  position: absolute;
  left: 0;
}

.exciseRefundsTransfer .ant-transfer-list-content-item{
  display: -webkit-flex;
  display: flex;
}

.exciseRefundsTransfer .LazyLoad{
  display: -webkit-flex;
  display: flex;
  min-height: 32px;
  height: auto !important;  
}

.preferredTable{
  max-height: 315px;
  overflow: scroll;
}

.preferredTable thead.ant-table-thead {
  display: none;
}

.homebasecard {
  margin-left: 10px !important;
}

.fleetCardsColumn {
  padding: unset !important;
}

/* .ant-carousel .slick-dots{
  mix-blend-mode: difference;
} */

.slick-dots li
{
  background-color: darkgray !important;
  border-radius: 25px;
  height: 5px;
}

.slick-dots li button
{
  background-color: darkgray !important;
  height: 5px;
  width: 20px !important;
  border-radius: 25px;
}

.slick-dots li.slick-active
{
  background-color: darkgray !important;
  border-radius: 25px;
  height: 5px;
}

.slick-dots li.slick-active button {
  background-color: dimgray !important;
  opacity: 0;
  height: 5px;
  width: 40px !important;
  border-radius: 25px;
}

.user-menu-menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  margin: 8px;
}

.user-menu-menu-item:hover {
  cursor: pointer;
  color: dodgerblue;
}

.user-menu-display-name {
  color: lightgray;
  font-size: 12px;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.trigger:hover {
  color: #1890ff;
}

.header-menu-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.header-menu-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  margin-right: 12px;
}

.logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

.loadingModal .ant-modal-content {
  background: transparent;
}

.custom-filter-dropdown {
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
  }
  
.custom-filter-dropdown input {
width: 200px;
margin-right: 8px;
}
  
.highlight {
color: #108ee9;
}
