.custom-filter-dropdown {
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
  }
  
.custom-filter-dropdown input {
width: 200px;
margin-right: 8px;
}
  
.highlight {
color: #108ee9;
}