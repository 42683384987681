.settings-form-title {
  margin: 16px 0;
}

.settings-form-element {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.settings-form-element-input {
  max-width: 200px !important;
}

.settings-form-element-title {
  margin-right: 16px;
  vertical-align: middle;
  width: 80%;
}

.ant-form-item {
  margin-bottom: 8px !important;
}

.settings-form-save-button {
  margin: 8px !important;
}

.settings-dropdown-menu {
  max-width: 200px !important;
}

.delete-refund-button {
  margin-left: 10px;
  cursor: pointer;
}

.settings-close-button-container {
  text-align: end !important;
  height: 56px;
  line-height: 56px;
  padding: 0;
}

.settings-close-button {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  padding: 16px;
}

.settings-container {
  height: 100%;
  background-color: white;
  padding: 16px 50px 50px;
  overflow: auto;
}

.uploadButton{
  text-align: center;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;;
	height: 50px;
  padding: 5px 15px;
}

.disableUpload{
  text-align: center;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  cursor: not-allowed !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;;
	height: 50px;
  padding: 5px 15px;
  color: darkgrey;
}

.disableUpload:hover{
  text-align: center;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  cursor: not-allowed !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;;
	height: 50px;
  padding: 5px 15px;
  color: darkgrey;
}

.platts-table .ant-table-thead > tr > th, .platts-table .ant-table-tbody > tr > td {
  padding: 10px 10px !important;
  white-space: nowrap !important;
}

.platts-table .ant-table-body{
  overflow: scroll;
}

.fleet-cards-table .ant-table-body{
  overflow-x: auto !important
}

.fleet-cards-table .ant-table-tbody > tr > td, .ant-table-thead > tr > th{
  border-bottom: 1px solid #bbbbbb
}

.dataColumns .ant-input{
  min-width: 200px !important;
}

.filterapplied{
  color: #1890ff;
}

.text-red{
  color: red;
}

.text-green{
  color: green;
}

.intend-text-60{
  text-indent: 60px;
}

.tabIcons{
  font-size: 14pt;
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.25em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  opacity: 0.7;
}

.checkCss{
  background-color: grey;
}

.editPopOver .ant-popover-arrow, .editPopOver .ant-popover-inner, .editPopOver .ant-popover-inner-content {
  background-color: #EEF1F4 !important;
}

.editPopOver .ant-popover-arrow{
  border-color: #EEF1F4 !important;
}

.editPopOver .ant-popover-inner-content{
  box-shadow: 3px 3px 15px 5px grey;
  border-radius: 7px;
}

.filterapplied{
  color: #1890ff;
}

.settingsButton{
  text-align: center;
  color: grey;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  font-size: 15pt;
  padding: 15px 5px 15px 0px;
}

.settingsButtonActive {
  text-align: center;
  color:  #1890ff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  font-size: 15pt;
  padding: 15px 5px 15px 0px;
}

.disableIcon{
  cursor: not-allowed !important;
  text-align: center;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  font-size: 15pt;
  padding: 15px 5px 15px 0px;
}

.fuelcardControl{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
}

.templateControl{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  padding-right: 10px;
}

.errorLabel{
  color: red;
  padding-bottom: 15px;
  padding-left: 10px;
}

.infoIcon{
  font-size: 18pt;
  color: #1890ff;
}

.fontBig{
  font-size: 20pt;
  color: #1890ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailWithCopy{
  cursor: 'text';
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ant-select-selection__rendered > ul{
  padding-right: 20px;
}

.btnSaveAndProcess{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.lastuploadedfileinfo{
  color: green;
}

.platts-table .ant-table-footer{
  padding: 8px 12px !important;
  font-weight: bold;
  font-style: italic;
}

.platts-table .ant-table-pagination{
  margin: 0px !important;
}

.trucks-table .ant-table-body{
  min-height: 270px;
}

.trucks-table .ant-pagination-total-text{
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
  position: absolute;
  left: 0;
}

.exciseRefundsTransfer .ant-transfer-list-content-item{
  display: flex;
}

.exciseRefundsTransfer .LazyLoad{
  display: flex;
  min-height: 32px;
  height: auto !important;  
}

.preferredTable{
  max-height: 315px;
  overflow: scroll;
}

.preferredTable thead.ant-table-thead {
  display: none;
}

.homebasecard {
  margin-left: 10px !important;
}

.fleetCardsColumn {
  padding: unset !important;
}

/* .ant-carousel .slick-dots{
  mix-blend-mode: difference;
} */

.slick-dots li
{
  background-color: darkgray !important;
  border-radius: 25px;
  height: 5px;
}

.slick-dots li button
{
  background-color: darkgray !important;
  height: 5px;
  width: 20px !important;
  border-radius: 25px;
}

.slick-dots li.slick-active
{
  background-color: darkgray !important;
  border-radius: 25px;
  height: 5px;
}

.slick-dots li.slick-active button {
  background-color: dimgray !important;
  opacity: 0;
  height: 5px;
  width: 40px !important;
  border-radius: 25px;
}
