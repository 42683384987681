@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.reflex-container.vertical > .reflex-splitter:hover, .reflex-container.vertical > .reflex-splitter.active {
  border-right: 2px solid dodgerblue !important;
  border-left: 2px solid dodgerblue !important;
}

.reflex-container.vertical > .reflex-splitter {
  border-right: 2px solid #c6c6c6 !important;
  border-left: 2px solid #c6c6c6 !important;
}

.mapboxgl-popup {
  z-index: 100;
  user-select: text;
  cursor: text;
}

.selectable-table-row {
  cursor: pointer;
}
.selectable-table-row.highlighted {
  background-color: #e6f7ff;
}