html body {
  font-family: roboto;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

tr.trailerRowSelected {
  background: #f0f0f0;
}
